import * as _ from 'lodash';
import * as Actions from '../actions';

const initialState = {
	devicesData: null,
	searchText: '',
	deviceData: null,
	deviceDialogData: {
		state: false,
		id: 'new'
	}
};

const devicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_DEVICES: {
			if (action.payload.length > 0) {
				const sortedData = _.sortBy(action.payload, 'name');
				return {
					...state,
					devicesData: sortedData
				};
			}

			return {
				...state,
				devicesData: []
			};
		}
		case Actions.SET_DEVICES_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_DEVICE: {
			return {
				...state,
				devicesData: state.devicesData.concat(action.payload)
			};
		}
		case Actions.GET_DEVICE: {
			return {
				...state,
				deviceData: action.payload
			};
		}
		case Actions.SAVE_DEVICE: {
			const data = state.devicesData.filter(item => item._id !== action.payload._id);
			const data2 = data.concat(action.payload);
			return {
				...state,
				deviceData: null,
				devicesData: data2
			};
		}
		case Actions.DEVICES_DIALOG_DATA: {
			return {
				...state,
				deviceDialogData: {
					state: action.payload.state,
					id: action.payload.id
				}
			};
		}
		case Actions.DELETE_DEVICE: {
			return {
				...state,
				delMapProvider: action,
				deviceData: null,
				devicesData: state.devicesData.filter(data => data._id !== action.payload._id)
			};
		}
		case Actions.CLEAR_DEVICES_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default devicesReducer;
