const locale = {
	APPLICATIONS: 'Applications',
	DASHBOARDS: 'Dashboards',
	MANAGEAGENTS: 'Agent Management',
	AGENTSMENU: 'Agents',
	NEWAGENT: 'New Agent',
	AGENTDETAILS: 'Agent Details',
	AGENTTOAGENTSUBS: 'Agent Subscriptions',
	CHANNELSUBS: 'Channel Subscriptions',
	GROUPSUBS: 'Group Subscriptions',
	MANAGECHANNELS: 'Channel Management',
	CHANNELSMENU: 'Channels',
	NEWCHANNEL: 'New Channel',
	MANAGEGROUPS: 'Group Management',
	GROUPSMENU: 'Groups',
	NEWGROUP: 'New Group',
	GROUPSSUBS: 'Group Subscriptions',
	CHANNELSSTATUS: 'Subscriptions Status',
	GROUPSSTATUS: 'Subscriptions Status',
	NEWAGENTSUB: 'New Agent Subscription',
	CHANNELDETAILS: 'Channel Details',
	TENANTSETTINGS: 'Tenant Settings',
	CALENDAR: 'Calendar',
	ECOMMERCE: 'E-Commerce',
	ACADEMY: 'Academy',
	MAIL: 'Mail',
	TODO: 'To-Do',
	FILE_MANAGER: 'File Manager',
	CONTACTS: 'Contacts',
	CHAT: 'Chat',
	SCRUMBOARD: 'Scrumboard',
	NOTES: 'Notes',

	TENANT: 'Tenant',
	TENANTSET: 'Tennant Settings',
	TENANTADMINS: 'Tenant Admins',
	MAPPROV: 'Map Providers',
	AGENTDEVICES: 'Agent Devices',
	RTP_MULTICAST_GROUPS: 'RTP Multicast Groups',
	VIDEO_STREAMS: 'Video Streams',
	RECORDING_HISTORY: 'Recording History',
	INTEGRATED_DEVICES: 'Integrated Devices',
	NFC_TAGS: 'NFC Tags'
};

export default locale;
