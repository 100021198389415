const primaryLocale = {
	WELCOME: 'Καλώς ορίσατε στην Εφαρμογή Διαχείρισης!',
	COMMANDSDS_WELCOME: 'Καλωσορίσατε στην πλατφόρμα CommandSDS!',
	COMMAND_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Command!',
	VIGILANCE_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Vigilance!',
	SAFEIN_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Safe In!',
	ACTIVE_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Active PTT!',
	AID_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Aid!',
	ASTRATAC_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Astratac!',
	PROTECTING_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Protecting!',
	SELECTA_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Selecta PTT!',
	TEMPEST_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Tempest!',
	NOVA_WELCOME: 'Καλωσορίσατε στην πλατφόρμα Nova PTT!',
	DIA_WELCOME: 'Καλωσορίσατε στην πλατφόρμα DIA!',
	SYSTELIOS_WELCOME: 'Καλωσορίσατε στην πλατφόρμα SyStelios PTT!',
	LOGIN: 'Σύνδεση στο λογαριασμό',
	AGENTS: 'Χρήστες',
	SEARCH: 'Αναζήτηση',
	ADDNEWAGENT: 'Προσθήκη Νέου Χρήστη',
	ADDMULTIAGENTS: 'Μαζική Προσθήκη Χρηστών',
	MULTI: 'Πολλαπλοί',
	NEW: 'Νέος',
	AGENT2AGENTSUBS: 'Δικαιώματα Χρήστη - Χρήστη',
	CHANNELS: 'Κανάλια',
	CHANNEL: 'Κανάλι',
	GROUPS: 'Ομάδες',
	// SEARCH: 'αναζήτηση',

	// Agent+basic
	SAVE: 'Αποθήκευση',
	BASICINFO: 'Πληροφορίες',
	AGENTSETTINGS: 'Ρυθμίσεις Χρήστη',
	REMOTESETTINGS: 'Ρυθμίσεις Απομακρυσμένου ελέγχου',
	AGENTDEVICES: 'Συσκευές Χρήστη',
	AGENTNAME: 'Όνομα Χρήστη',
	USERNAME: 'Όνομα Χρήστη',
	PASSWORD: 'Κωδικός',
	USERSETTINGS: 'Ρυθμίσεις Χρήστη',
	USERSAVED: 'Ο Χρήστης αποθηκεύτηκε',
	USERACTIVATE: 'Ενεργοποίηση Χρήστη',
	SETNEWPASS: 'Ορισμός Νέου Κωδικού',
	NEWPASS: 'Νέος Κωδικός',
	REPASS: 'Επανεισαγωγή κωδικού',
	CANCEL: 'Ακύρωση',
	UPDATEUSER: 'Ενημέρωση',
	// AgentSettings
	EMERGENCYCHANNEL: 'Κανάλι Έκτακτης Ανάγκης',
	AGENTUISETTINGS: 'Ρυθμίσεις UI Χρήστη',
	ALAGENTLIST: 'Κατάλογος Χρηστών',
	ALCHANNELLIST: 'Κατάλογος Καναλιών',
	ALGROUPLIST: 'Κατάλογος Ομάδων',
	ALCHATVIEW: 'Κατάλογος μηνυμάτων',
	ALTASKS: 'Tasks',
	ALMAPS: 'Χάρτες',
	ALINMAPS: 'Εσωτερικοί Χάρτες',
	ALJOURNAL: 'Journal',
	WEATHERFORECAST: 'Πρόγνωση Καιρού',
	COMMUNICATIONSSETTINGS: 'Ρυθμίσεις Επικοινωνιών',
	ALDONOTDIST: 'Μην Ενοχλείτε',
	ALHQ: 'HQ',
	ALMAKEDPTT: 'Άμεση Κλήση PTT',
	ALREDPTT: 'Λήψη Άμεσων Κλήσεων PTT',
	ALMAKEE2EE: 'E2EE',
	ALREE2EE: 'Λήψη E2EE',
	ALMAKEEMERGENCY: 'Επείγουσα Κλήση',
	ALREEMERGENCY: 'Λήψη Επείγουσας Κλήσης',
	ALMAKEGROUPCALL: 'Ομαδικές Κλήσεις',
	ALREGROUPCALL: 'Λήψη Ομαδικών Κλήσεων',
	ALMAKEINDCALL: 'Ατομικές Κλήσεις',
	ALREINDCALL: 'Λήψη Ατομικών Κλήσεων',
	ALSENDTXT: 'Αποστολή Γραπτών Μηνυμάτων',
	ALRETXT: 'Λήψη Γραπτών Μηνυμάτων',
	ALSENDATT: 'Αποστολή Συνημμένων',
	ALREATT: 'Λήψη Συνημμένων',
	LOCATIONSETT: 'Ρυθμίσεις Τοποθεσίας',
	ALLOWRECEIVENAVIGATIONS: 'Λήψη Οδηγιών Πλοήγησης',
	ENLOCATSETT: 'Διαμοιρασμός Θέσης',
	LOCSHARINT: 'Χρονικό Διάστημα Διαμοιρασμού Θέσης',
	LOCUPDATEDIST: 'Ελάχιστη Απόσταση Ενημέρωσης',
	METERS: 'Μέτρα',
	LOCUPDATEMODE: 'Λειτουργία Ενημέρωσης Θέσης',
	INTERVAL: 'Χρονικό Διάστημα',
	DISTANCE: 'Απόσταση',
	HYBRID: 'Υβριδική',
	HISTORYSETT: 'Ρυθμίσεις Ιστορικού',
	MAXHISTITEMS: 'Μέγιστος Αριθμός Στοιχείων',
	ITEMS: 'Στοιχεία',
	MAXHISTPERIOD: 'Μέγιστη Περίοδος Ιστορικού',
	MIN: 'Λεπτά',
	SECONDS: 'Δεύτερα',
	COMMANDCENTERSETT: 'Ρυθμίσεις CommandCenter',
	ALCOMMCENTER: 'Command Center',
	ALMAPTEDITOR: 'Εργαλεία Χάρτη',
	ALDGNAVIEW: 'DGNA',
	DEVSETT: 'Ρυθμίσεις συσκευής',
	DEVICEINFORMATION: 'Πληροφορίες Συσκευής',
	// ALDEVINFOSHARING: 'Διαμοιρασμός Πληροφοριών Συσκευής',
	// DEVINFOSHARINT: 'Χρονικό Διάστημα Διαμοιρασμού',
	// Agent Remote Settings
	REMICSETT: 'Ρυθμίσεις Απομακρυσμένου Μικροφώνου',
	ALRMICCONTROL: 'Έλεγχος Απομακρυσμένου Μικροφώνου',
	ALRMICSHAR: 'Διαμοιρασμός Μικροφώνου Απομακρυσμένα',
	ALRMICNOT: 'Ειδοποίηση Απομακρυσμένου Μικροφώνου',
	REPICSETT: 'Ρυθμίσεις Απομακρυσμένης Εικόνας',
	ALRPICCONTROL: 'Έλεγχος Απομακρυσμένης Εικόνας',
	ALRPICSHAR: 'Διαμοιρασμός Απομακρυσμένης Εικόνας',
	ALRPICNOT: 'Ειδοποίηση Απομακρυσμένης Εικόνας',
	REVIDSETT: 'Ρυθμίσεις Απομακρυσμένου Βίντεο',
	ALRVIDCONTROL: 'Έλεγχος Απομακρυσμένου Βίντεο',
	ALRVIDSHAR: 'Διαμοιρασμός Απομακρυσμένου Βίντεο',
	ALRVIDNOT: 'Ειδοποίηση Απομακρυσμένου Βίντεο',
	DEVICES: 'Συσκευές',
	// AGENTSTABLEHEADER
	NAME: 'Ονομα',
	STREAM_NAME: 'Όνομα Ροής',
	DEVICE_NAME: 'Όνομα Συσκευής',
	NFC_TAG_NAME: 'Όνομα Ετικέτας NFC',
	UNIQUE_ID: 'Μοναδικό ID',
	DEVICE_ID: 'ID Συσκευής',
	DEVICE_TYPE: 'Τύπος Συσκευής',
	CURRCHUNNEL: 'Τρέχον Κανάλι',
	ISEMERG: 'Επείγουσα Κατάσταση',
	DNDIST: 'Μην Ενοχλείτε',
	ISONLINE: 'Σε Σύνδεση',
	LASTACT: 'Τελευταία Εμφάνηση',
	LASTLOC: 'Τελευταία Θέση',

	// AGENT2AGENTSUBS
	SOURCEAGENT: 'Χρήστης',
	NONE: '---',
	NEWSUB: 'Νέα Εγγραφή',
	TARGETAGENT: 'Χρήστης Αναφοράς',
	INDICALL: 'Ατομικές Κλήσεις',
	DIRPTT: 'Άμεση Κλήση PTT',
	E2EE: 'E2EE',
	MUTE: 'Σίγαση',
	TXTMES: 'Μηνύματα Κειμένου',
	ATTACH: 'Συνημμένα',
	RMMICCONT: 'Έλεγχος Μικροφώνου Απομακρυσμένα',
	RMPICCONT: 'Έλεγχος Εικόνας Απομακρυσμένα',
	RMVIDCONT: 'Έλεγχος Βίντεο Απομακρυσμένα',
	RMMICNOTIF: 'Ηχητική Ειδοποίηση Απομακρυσμένου Μικροφώνου',
	RMPICNOTIF: 'Ηχητική Ειδοποίηση Απομακρυσμένης Εικόνας',
	RMVIDNOTIF: 'Ηχητική Ειδοποίηση Απομακρυσμένου Βίντεο',
	RMSCREENCONT: 'Έλεγχος Οθόνης Απομακρυσμένα',
	VIEWDEVINFO: 'Πληροφορίες Συσκευής',
	ALLIVELOC: 'Τρέχουσα Τοποθεσία',
	ALLHISLOC: 'Ιστορικό Τοποθεσίας',

	// AGENT2AGENTSUB
	AGENTSSELECTION: 'Επιλογή Χρηστών',
	PERMIS: 'Δικαιώματα',
	RMPERM: 'Δικαιώματα Απομακρυσμένης Χρήσης',
	MICPERM: 'Δικαιώματα Μικροφώνου',
	PICPERM: 'Δικαιώματα Εικόνας',
	VIDPERM: 'Δικαιώματα Βίντεο',
	DEVPERM: 'Δικαιώματα Συσκευής',

	// CHANNELSSUBS
	CHANNELSSUBS: 'Δικαιώματα Καναλιών',
	CHANNELSSUB: 'Δικαιώματα Καναλιού',
	CHANNELSSTATUS: 'Κατάσταση Εγγραφών Καναλιών',
	TARGETCHANN: 'Κανάλι Αναφοράς',

	// GROUPSSUBS
	ADDNEWGROUP: 'Προσθήκη Νέας Ομάδας',
	GROUPSSUBS: 'Δικαιώματα Ομάδων',
	GROUPSSUB: 'Δικαιώματα Ομάδας',
	GROUPSSTATUS: 'Κατάσταση Εγγραφών Ομάδων',
	TARGETGROUP: 'Ομάδα Αναφοράς',
	AGENT: 'Χρήστης',
	GROUP: 'Ομάδα',
	// TABLE
	ALJOIN: 'Σύνδεση',
	ALSPEAK: 'Ομιλία',
	PRIORITY: 'Προτεραιότητα',
	ALLISTEN: 'Ακρόαση',
	// CHANNELSUB
	SELECTION: 'Επιλογή',
	COMMPERM: 'Δικαιώματα Επικοινωνίας',
	MSGPERM: 'Δικαιώματα Μηνυμάτων',

	// CHANNELSMNGMENT
	// chanel
	NEWCHANNEL: 'Νέο Καναλι',
	CHANNELINFO: 'Πληροφορίες Καναλιού',
	CHANNELNAME: 'Όνομα Καναλιού',
	DESCRIPTION: 'Περιγραφή',
	CHANNCOLOR: 'Χρώμα Καναλιού',
	SETTINGS: 'Ρυθμίσεις',
	ISDYNAMIC: 'Δυναμικό',
	ISENABLED: 'Ενεργό',
	ISMUTED: 'Σε Σίγαση',
	// channels
	COLOR: 'Χρώμα',
	LASTMESSAGE: 'Τελευταίο Μήνυμα',
	LASTUPDATE: 'Τελευταία Ενημέρωση',
	// channelStatus
	CHANELLSUBSSTAT: 'Κατάσταση Εγγραφών Καναλιών',
	ALWAYSLISTENING: 'Σε Μόνιμη Ακρόαση',
	DEVICE: 'Συσκευή',
	SESSION: 'Συνεδρία',

	// GROUPSMNGMENT
	// GROUP
	NEWGROUP: 'Νέα Ομάδα',
	GROUPINFO: 'Πληροφορίες Ομάδας',
	GROUPNAME: 'Όνομα Ομάδας',
	GROUPCOLOR: 'Χρώμα Ομάδας',
	// GROUPStatus
	GROUPSUBSSTAT: 'Κατάσταση Εγγραφών Ομάδων',

	// TENSETTINGS
	TENANTSETTINGS: 'Ρυθμίσεις Φορέα',
	ADDNEWSETT: 'Προσθήκη Νέου',
	KEY: 'Κλειδί',
	VALUE: 'Τιμή',
	VALUETYPE: 'Τύπος Τιμής',

	// MAP PROVIDERS
	MAPPROVS: 'Πάροχοι Χαρτών',
	URI: 'URI',
	SECKEY: 'Δευτερεύον κλειδί',
	EXTDATA: 'Πρόσθετα δεδομένα',

	// ADMINS
	ROLES: 'Ρόλοι',
	ISACTIVE: 'Ενεργός',
	TENANTADMINS: 'Διαχειριστές Φορέα',
	UPDATEADMIN: 'Ενημέρωση Διαχειριστή',
	CREATENEWADMIN: 'Δημιουργία Νέου Διαχειριστή',
	ADMINSETTINGS: 'Ρυθμίσεις Διαχειριστή',

	SETPASS: 'Ορίστε Κωδικό',
	SETCREDS: 'Ορίστε Διαπιστευτήρια',
	SETCREDSFOR: 'Ορίστε Διαπιστευτήρια Για ',
	PASS: 'Κωδικός',
	AGENTDEVICE: 'Συσκευή Χρήστη',
	SUPERADMINS: 'Υπέρ-Διαχειριστής',

	IMEI: 'IMEI',
	BRANDNAME: 'Κατασκευαστής',
	FIRMW: 'Firmware',
	MODELVER: 'Έκδοση μοντέλου',
	PLATFORM: 'Πλατφόρμα',
	SERIALN: 'Σειριακός Αριθμός',
	SELECTAGENT: 'Χρήστης',
	NEWDEVICE: 'Νέα Συσκευή',
	DEVICE_ID_ALREADY_EXISTS: 'Το αναγνωριστικό συσκευής υπάρχει ήδη!',
	NFC_TAG_ID_ALREADY_EXISTS: 'Το αναγνωριστικό NFC υπάρχει ήδη!',
	ROLE: 'Ρόλος',
	TENANTS: 'Φορείς',
	TENANT: 'Φορέας',
	CREATED: 'Δημιουργία',

	ISADMIN: 'Διαχειριστής:',

	INFOS: 'Πληροφορίες',
	MOREINFO: 'Περισσότερες πληροφορίες',
	USERS: 'Χρήστες',
	MAPSINFO: 'Πληροφορίες Χαρτών',
	AGENTLOCATIONS: 'Θέσεις Χρηστών',
	POIS: 'Σημεία Ενδιαφέροντος',
	WAYPOINTS: 'Σημεία Αναφοράς',
	GEOFENCES: 'Γεωζώνες',
	FILES: 'Αρχεία',
	//  Chenck
	DEVICEINFO: 'Πληροφορίες Συσκευών',

	CLICKSETTINGS: 'Κάντε Κλικ Για Ρυθμίσεις',
	V2IMPLEMENTATION: 'Υλοποίηση στην Έκδοση 2.0',
	TOOTLTIPS: 'Υποδείξεις',
	BATTERYOPT: 'Βελτιστοποίηση Μπαταρίας',
	MESGES: 'Μηνύματα',
	//  confirmation dialog
	CONFIRMATIONDIALOGTITLE: 'Επιβεβαίωση Ενέργειας',
	DELETESUB: 'Είστε σίγουρος για τη διαγραφή;',
	DELETEADMIN: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτόν τον διαχειριστή;',
	DELETEMAPPROVIDER: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτόν τον χάρτη;',
	DELETE_RTP_MULTICAST_GROUP_CONF: 'Είστε σίγουρος πως θέλετε να διαγράψετε την Ομάδα Πολυεκπομπής RTP;',
	DELETE_VIDEO_STREAM_CONF: 'Είστε σίγουρος πως θέλετε να διαγράψετε την Ροή Βίντεο;',
	DELETE_DEVICE_CONF: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτήν τη συσκευή;',
	DELETE_NFC_TAG_CONF: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτήν την ετικέτα NFC;',
	DELETETENANTSET: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτόν Tenant;',
	DELETEDEVICE: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτήν τη συσκευή;',
	DELETEAGENT: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτόν τον χρήστη; Πληροφορίες Χρήστη: { ',
	AGENTSSUB: 'Δικαιώματα Χρήστη-Χρήστη: ',
	CHANNELSSUB: 'Δικαιώματα στο Κανάλι: ',
	GROUPSUB: 'Δικαιώματα στην Ομάδα: ',
	MESSAGES: 'Μηνύματα: ',
	DELETECHANNEL: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτό το κανάλι; Πληροφορίες καναλιού: { ',
	DELETEGROUP: 'Είστε σίγουρος πως θέλετε να διαγράψετε αυτήν την ομάδα; Πληροφορίες ομάδας: { ',
	// show message
	AGENTSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	AGENTSSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	AGENTDELETED: 'Η διαγραφή ολοκληρώθηκε',
	ADMINSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	ADMINROLEDELETED: 'Η διαγραφή ολοκληρώθηκε',
	ADMINDELETED: 'Η διαγραφή ολοκληρώθηκε',
	SUBSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	SUBSSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	SUBDELETED: 'Η διαγραφή ολοκληρώθηκε',
	DEVICESAVED: 'Η αποθήκευση ολοκληρώθηκε',
	DEVICEDELETED: 'Η διαγραφή ολοκληρώθηκε',
	CHANNELSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	CHANNELDELETED: 'Η διαγραφή ολοκληρώθηκε',
	GROUPSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	GROUPDELETED: 'Η διαγραφή ολοκληρώθηκε',
	MAPPROVSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	MAPPROVDELETED: 'Η διαγραφή ολοκληρώθηκε',
	RTP_MULTICAST_GROUP_SAVED: 'Η αποθήκευση της ομάδες πολυεκπομπής RTP ολοκληρώθηκε',
	RTP_MULTICAST_GROUP_DELETED: 'Η διαγραφή της ομάδες πολυεκπομπής RTP ολοκληρώθηκε',
	VIDEO_STREAM_SAVED: 'Η αποθήκευση της ροής βίντεο ολοκληρώθηκε',
	VIDEO_STREAM_DELETED: 'Η διαγραφή της ροής βίντεο ολοκληρώθηκε',
	DEVICE_SAVED: 'Η αποθήκευση της συσκευής ολοκληρώθηκε',
	DEVICE_DELETED: 'Η διαγραφή της συσκευής ολοκληρώθηκε',
	NFC_TAG_SAVED: 'Η αποθήκευση της ετικέτας NFC ολοκληρώθηκε',
	NFC_TAG_DELETED: 'Η διαγραφή της ετικέτας NFC ολοκληρώθηκε',
	DELETEDSUPERADMIN: 'Η διαγραφή ολοκληρώθηκε',
	SAVEDSUPERADMIN: 'Η αποθήκευση ολοκληρώθηκε',
	TENANTSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	TENANTDELETED: 'Η διαγραφή ολοκληρώθηκε',
	TENANTSETTINGSSAVED: 'Η αποθήκευση ολοκληρώθηκε',
	TENANTSETTINGSDELETED: 'Η διαγραφή ολοκληρώθηκε',

	LICENSEGENINFO: 'Γενικές Πληροφορίες Άδειας Χρήσης',
	LICENSESTATE: 'Κατάσταση Άδειας Χρήσης',
	LICENSE: 'Άδεια',
	EXPDATE: 'Ημερομηνία λήξης',
	DATE: 'Ημερομηνία',

	TOTAL_USERS_ALLOWED: 'Επιτρεπόμενοι Χρήστες',
	AGENT_USERS: 'Χρήστες',
	CC_USERS: 'Command Center Χρήστες',

	TOTAL_ONLINE_USERS_ALLOWED: 'Επιτρεπόμενοι Ενεργοί Χρήστες',
	ONLINE_AGENT_USERS: 'Ενεργοί Χρήστες',
	ONLINE_CC_USERS: 'Ενεργοί Command Center Χρήστες',

	CURRENT_CREATED_USERS: 'Τρέχων Αριθμός Χρηστών',
	CURRENT_ONLINE_USERS: 'Τρέχων Αριθμός Ενεργών Χρηστών',

	TOTALAGENTSCREATED: 'Αριθμός Ενεργών Χρηστών',
	TOTALCCCREATED: 'Αριθμός Ενεργών Χρηστών Command Center',

	ALLOWAGENTPROTECTION: 'Λειτουργία Agent Protection',
	ALLOWSELFAGENTPROTECTION: 'Self Agent Protection',
	ALLOWEDITAGENTPROTECTION: 'Μεταβολή Agent Protection',
	ENABLECOMMANDCENTER: 'Enable Allow Command Center first',
	ENABLEALLOWAP: 'Enable Allow Agent Protection first',
	TOOLTIPS: 'Tooltips',
	ENABLEDGNA: 'Ενεργοποιήση Διαχείρισης DGNA',
	CHARTS: 'Διαγράμματα',
	DISPLAYNAME: 'Εμφάνιση Ως',
	ADDNEWMAPPROV: 'Προσθήκη Νέου Παρόχου Χαρτών',
	UPADTEMAPPROV: 'Ενημέρωση Παρόχου Χάρτη',
	ADD_NEW_RTP_MULTICAST_GROUP: 'Προσθήκη Νέας Ομάδας Πολυεκπομπής RTP',
	UPDATE_RTP_MULTICAST_GROUP: 'Ενημέρωση Ομάδας Πολυεκπομπής RTP',
	ADD_NEW_VIDEO_STREAM: 'Προσθήκη Νέας Ροής Βίντεο',
	UPDATE_VIDEO_STREAM: 'Ενημέρωση Ροής Βίντεο',
	ADD_NEW_DEVICE: 'Προσθήκη Νέας Συσκευής',
	UPDATE_DEVICE: 'Ενημέρωση Συσκευής',
	ADDNEWTENANTSET: 'Προσθήκη Νέου Tenant Setting',
	UPADTETENANTSET: 'Ενημέρωση Tenant Setting',
	ADDNEWTENANT: 'Προσθήκη Νέου Tenant',
	UPADTETENANT: 'Ενημέρωση Tenant',
	UPDATEGROUP: 'Ενημέρωση Group',
	ADDNEWGROUP: 'Προσθήκη Νέου Group',
	UPDATECHANNEL: 'Ενημέρωση Καναλιού',
	ADDNEWCHANNEL: 'Προσθήκη Νέου Καναλιού',
	CENTERCLIENT: ' Center Client',
	MOBILECLIENT: ' Mobile Client',
	ADMINCLIENT: ' Administration Client',
	POWEREDBYEYEONIX: 'Powered by Eyeonix',
	DOWNLOADEXE: 'Πατήστε το κουμπί λήψης παρακάτω, όταν ολοκληρωθεί η λήψη, εκτελέστε το αρχείο .exe.',
	DOWNLOADAPK:
		'Πατήστε το κουμπί λήψης παρακάτω, όταν ολοκληρωθεί η λήψη, εκτελέστε το αρχείο .apk στο Android κινητό σας.',
	DOWNLOAD: 'Download',
	BASICTENANTINFO: 'Πληροφορίες Φορέα',
	DLCLIENTS: 'Download Clients',
	ENTERNAME: 'Όνομα Υποχρεωτικό',
	ENTERDESCR: 'Περιγραφή Υποχρεωτική',
	MAPDATA: 'Γεωγραφικά Δεδομένα',
	CREATEMULTIAGENTS: 'Δημιουργία Μαζικών Χρηστών',
	AG2AGMULTISUB: 'Μαζικά Δικαιώματα Χρήστη-Χρήστη',
	CREATEAG2AGMULTISUB: 'Δημιουργία Μαζικών Δικαιωμάτων σε Χρήστη',
	AG2AGESINGLESUB: 'Δικαιώματα Χρήστη-Χρήστη',
	CREATEMULTICHANNELSUBS: 'Δημιουργία Μαζικών Δικαιωμάτων σε Κανάλι',
	CREATESINGLECHANNELSUB: 'Δημιουργία Δικαιωμάτων σε Κανάλι',
	MULTICHANNELSUBS: 'Μαζικά Δικαιώματα σε Κανάλι',

	MULTIGROUPSUBS: 'Μαζικά Δικαιώματα σε Ομάδα',
	MULTISUBS_SUCCESS_CREATED: 'Τα πολλαπλά δικαιόματα δημιουργήθηκαν με επιτυχία',
	CREATEMULTIGROUPSUBS: 'Δημιουργία Μαζικών Δικαιωμάτων σε Ομάδα',
	CREATESINGLEGROUPSUB: 'Δημιουργία Δικαιωμάτων σε Ομάδα',
	AGENTSLEFT: 'Υπόλοιπο Χρηστών',
	CCLEFT: 'Υπόλοιπο CommandCenters',
	COORDS: 'Συντεταγμένες',
	SEARCHINFO: 'Πληροφορίες Αναζήτησης',
	LATITUDE: 'Γεωγραφικό Πλάτος',
	LONGITUDE: 'Γεωγραφικό Μήκος ',
	MAPSEARCH: 'Εισαγωγή διεύθυνσης/συντεταγμένων',
	CLOSE: 'Κλείσιμο',
	SEARCHTARGETAGENTS: 'Εύρεση Χρηστών Αναφοράς',
	SEARCHTARGETCHANNELS: 'Εύρεση Καναλιών Αναφοράς',
	SEARCHTARGETGROUPS: 'Εύρεση Group Αναφοράς',
	WORKFLOW: 'FlowLogic',
	WORKFLOWSETTINGS: 'Ρυθμίσεις Ροής Εργασίας',
	CREATE: 'Δημιουργία',
	UPDATE: 'Ανανέωση',
	DELETE: 'Διαγραφή',
	WORKFLOWASSINGMENT: 'Ανάθεση Ροής Εργασίας',
	SCREENPERM: 'Δικαιώματα Οθόνης',
	COMMUNICATIONPERMISSIONS: 'Δικαιώματα Επικοινωνιών',
	FORCEGPS: 'Υποχρεωτική Λειτουργία GPS',
	HEALTHVITALSIGNS: 'Ζωτικά Σημεία Υγείας',
	ENABLESHARING: 'Ενεργοποίηση Διαμοιρασμού',
	SHARINGINTERVAL: 'Χρονικό Διάστημα Διαμοιρασμού',
	INDIVIDEOCALL: 'Ατομικές Βίντεο Κλήσεις',
	EDITUSERCREDENTIAL: 'Επεξεργασία διαπιστευτηρίων χρήστη',
	LICENSE_EXCEEDED: 'Υπέρβαση Άδειας Χρήσης',
	REMAIN: 'Απομένουν',
	COMMANDCENTERS: 'Command Centers',
	FROM: 'Από',
	TO: 'Έως',
	SATELLITE_IMAGERY: 'Δορυφορικές Εικόνες',
	RTP_MULTICAST_GROUPS: 'Ομάδες Πολυεκπομπής RTP',
	MULTICAST_IP: 'IP Πολυεκπομπής',
	MULTICAST_PORT: 'Θύρα Δικτύου Πολυεκπομπής',
	NOT_VALID_IP: 'Μη έγκυρη διεύθυνση IP!',
	MULTICAST_IP_IN_USE: 'Η διεύθυνση πολυεκπομπής IP χρησιμοποιείται!',
	NOT_VALID_PORT: 'Μη έγκυρη θύρα δικτύου!',
	CHANNEL_IN_USE: 'Το κανάλι χρησιμοποιείται!',
	VIDEO_STREAMS: 'Ροές Βίντεο',
	VIDEO_STREAM: 'Ροή Βίντεο',
	SERVER: 'Διακομιστής',
	PORT: 'Θύρα',
	PATH: 'Διαδρομή',
	CREDENTIALS: 'Διαπιστευτήρια',
	LOCATION: 'Τοποθεσία',
	PERMITTED_AGENTS: 'Επιτρεπόμενοι Χρήστες',
	RECORDING_HISTORY: 'Ιστορικό Εγγραφών Βίντεο',
	INTEGRATED_DEVICES: 'Ενσωματωμένες Συσκευές',
	ID_MUST_BE_15_DIGITS: 'Το ID πρέπει να αποτελείται από 15 ψηφία!',
	IMEI_OR_SERIAL: 'IMEI ή Σειριακός Αριθμός',
	TRACKER: 'Συσκευή Εντοπισμού Θέσης',
	DRONE: 'Μη επανδρωμένο αερόχημα',
	BODY_CAMERA: 'Κάμερα Σώματος',
	PROTOCOL: 'Πρωτόκολλο',
	TYPE: 'Τύπος',
	ALLOW_SPEECH_TO_TEXT: 'Επιτρέψτε την Ομιλία σε Κείμενο',
	NFC_TAGS: 'Ετικέτες NFC',
	ADD_NEW_NFC_TAG: 'Προσθήκη Νέας Ετικέτας NFC',
	UPDATE_NFC_TAG: 'Ενημέρωση Ετικέτας NFC',
	ALLOW_NFC_TAGS: 'Επιτρέψτε τις Ετικέτες NFC'
};

const locale = {
	...primaryLocale,
	DELETEAGENTTOOLTIP: `${primaryLocale.DELETE} χρήστη`,
	DELETECHANNELSUB: `${primaryLocale.DELETE} δικαιώματος καναλιού`,
	DELETEGROUPSUB: `${primaryLocale.DELETE} εγγραφής ομάδας`,
	DELETEA2ASUB: `${primaryLocale.DELETE} δικαιώματος χρήστη`,
	DELETEAGENTDEVICE: `${primaryLocale.DELETE} συσκευής χρήστη`,
	DELETECHANNELTOOLTIP: `${primaryLocale.DELETE} καναλιού`,
	DELETEGROUPTOOLTIP: `${primaryLocale.DELETE} ομάδας`,
	DELETETENANTTOOLTIP: `${primaryLocale.DELETE} φορέα`,
	DELETEMAPPROVIDERTOOLTIP: `${primaryLocale.DELETE} χάρτη`,
	DELETE_RTP_MULTICAST_GROUP: `${primaryLocale.DELETE} ομάδας πολυεκπομπής RTP`,
	DELETETENANTSETTINGTOOLTIP: `${primaryLocale.DELETE} ρύθμισης φορέα`,
	DELETE_VIDEO_STREAM: `${primaryLocale.DELETE} ροής βίντεο`,
	DELETE_DEVICE: `${primaryLocale.DELETE} συσκευής`,
	DELETE_NFC_TAG: `${primaryLocale.DELETE} ετικέτας NFC`,
	AVAILABLE_CHANNELS: 'Διαθέσιμα Κανάλια',
	SELECTED_CHANNELS: 'Επιλεγμένα Κανάλια',
	AVAILABLE_GROUPS: 'Διαθέσιμες Ομάδες',
	SELECTED_GROUPS: 'Επιλεγμένες Ομάδες',
	AVAILABLE_AGENTS: 'Διαθέσιμοι Χρήστες',
	SELECTED_AGENTS: 'Επιλεγμένοι Χρήστες',
	DAYS_LEFT: 'Υπολειπόμενες Μέρες',
	EXPIRED: 'Έληξε',
	DAYS_AGO: 'Μέρες Πριν',
	CC_CLIENT_NOT_AVAILABLE:
		'Η εφαρμογή CommandCenter δεν είναι διαθέσιμη, επικοινωνήστε με τον διαχειριστή του συστήματός σας',
	CM_CLIENT_NOT_AVAILABLE:
		'Η Android εφαρμογή δεν είναι διαθέσιμη, επικοινωνήστε με τον διαχειριστή του συστήματός σας'
};
export default locale;
