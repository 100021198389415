import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_VIDEO_STREAM = '[MANAGE-COMMAND APP] GET VIDEO_STREAM';
export const SET_VIDEO_STREAMS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET VIDEO_STREAMS SEARCH TEXT';
export const NEW_VIDEO_STREAM = '[MANAGE-COMMAND APP] NEW VIDEO_STREAM';

export const GET_VIDEO_STREAMS = '[MANAGE-COMMAND APP] GET VIDEO_STREAMS';
export const SAVE_VIDEO_STREAM = '[MANAGE-COMMAND APP] SAVE VIDEO_STREAM';
export const DELETE_VIDEO_STREAM = '[MANAGE-COMMAND APP] DELETE VIDEO_STREAM';
export const CLEAR_VIDEO_STREAMS_STORE = '[MANAGE-COMMAND APP] CLEAR VIDEO_STREAMS STORE';
export const VIDEO_STREAMS_DIALOG_DATA = '[MANAGE-COMMAND APP] VIDEO_STREAMS DIALOG DATA';

export function clearVideoStreamsStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_VIDEO_STREAMS_STORE
		});
}

export function videoStreamDialogData(state) {
	return {
		type: VIDEO_STREAMS_DIALOG_DATA,
		payload: state
	};
}

export function newVideoStream(data, message) {
	const request = axios.post('EpttaAdmin/VideoStreams', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: NEW_VIDEO_STREAM,
				payload: response.data
			});
		});
}

export function getVideoStreams() {
	const request = axios.get(`EpttaAdmin/VideoStreams`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_VIDEO_STREAMS,
				payload: response.data
			})
		);
}

export function setVideoStreamsSearchText(event) {
	return {
		type: SET_VIDEO_STREAMS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getVideoStream(streamId) {
	const request = axios.get(`EpttaAdmin/VideoStreams/${streamId}`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_VIDEO_STREAM,
				payload: response.data
			})
		);
}

export function saveVideoStream(data, message) {
	const request = axios.put('EpttaAdmin/VideoStreams', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: SAVE_VIDEO_STREAM,
				payload: data
			});
		});
}

export function deleteVideoStream(data, message) {
	const request = axios.delete(`EpttaAdmin/VideoStreams?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: DELETE_VIDEO_STREAM,
				payload: data
			});
		});
}

export function newVideoStreamsTemplate(tenant) {
	const data = {
		_id: 'new',
		tenantId: tenant,
		name: '',
		cameraName: '',
		server: '',
		nvrPort: '',
		port: '',
		path: '',
		isSSL: true,
		username: '',
		password: '',
		latitude: null,
		longitude: null,
		permittedAgents: [],
		created: '2024-03-30T12:13:24.854Z',
		lastUpdate: '2024-03-30T12:13:24.854Z'
	};

	return {
		type: GET_VIDEO_STREAM,
		payload: data
	};
}
