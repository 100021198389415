import axios from 'axios';
// import { showMessage } from 'react/app/store/actions/fuse';

export const GET_DEVICES_LOCATIONS = '[MANAGE-COMMAND APP] GET DEVICES_LOCATIONS'; // akisx
export const CLEAR_DEVICES_LOCATIONS_STORE = '[MANAGE-COMMAND APP] CLEAR DEVICES LOCATIONS STORE'; // akisx

export function clearDeviceLocationsStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_DEVICES_LOCATIONS_STORE
		});
}

export function getDevicesLocations() {
	const request = axios.get(`EpttaAdmin/DevicesLastLocations`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_DEVICES_LOCATIONS,
				payload: response.data
			})
		);
}
