import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_NFC_TAG = '[MANAGE-COMMAND APP] GET NFC TAG';
export const SET_NFC_TAGS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET NFC TAGS SEARCH TEXT';
export const NEW_NFC_TAG = '[MANAGE-COMMAND APP] NEW NFC TAG';

export const GET_NFC_TAGS = '[MANAGE-COMMAND APP] GET NFC TAGS';
export const SAVE_NFC_TAG = '[MANAGE-COMMAND APP] SAVE NFC TAG';
export const DELETE_NFC_TAG = '[MANAGE-COMMAND APP] DELETE NFC TAG';
export const CLEAR_NFC_TAGS_STORE = '[MANAGE-COMMAND APP] CLEAR NFC TAGS STORE';
export const NFC_TAGS_DIALOG_DATA = '[MANAGE-COMMAND APP] NFC TAGS DIALOG DATA';

export function clearNfcTagsStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_NFC_TAGS_STORE
		});
}

export function nfcTagDialogData(state) {
	return {
		type: NFC_TAGS_DIALOG_DATA,
		payload: state
	};
}

export function newNfcTag(data, message, errorMessage) {
	const request = axios.post('EpttaAdmin/NFC', data);

	return dispatch =>
		request.then(response => {
			if (response.status === 226) {
				return dispatch(showMessage({ variant: 'error', message: errorMessage }));
			}

			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: NEW_NFC_TAG,
				payload: response.data
			});
		});
}

export function getNfcTags() {
	const request = axios.get(`EpttaAdmin/NFC`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_NFC_TAGS,
				payload: response.data
			})
		);
}

export function setNfcTagsSearchText(event) {
	return {
		type: SET_NFC_TAGS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getNfcTag(streamId) {
	const request = axios.get(`EpttaAdmin/NFC/${streamId}`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_NFC_TAG,
				payload: response.data
			})
		);
}

export function saveNfcTag(data, message) {
	const request = axios.put('EpttaAdmin/NFC', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: SAVE_NFC_TAG,
				payload: data
			});
		});
}

export function deleteNfcTag(data, message) {
	const request = axios.delete(`EpttaAdmin/NFC?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: DELETE_NFC_TAG,
				payload: data
			});
		});
}

export function newNfcTagsTemplate(tenant) {
	const data = {
		_id: 'new',
		tenantId: tenant,
		uid: '',
		name: '',
		description: '',
		created: '2024-03-30T12:13:24.854Z',
		lastUpdate: '2024-03-30T12:13:24.854Z'
	};

	return {
		type: GET_NFC_TAG,
		payload: data
	};
}
