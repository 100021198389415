import { combineReducers } from 'redux';
import agents from './agents.reducer';
import user from './user.reducer';
import agent2AgentSubs from './agent2AgentSubs.reducer';
import channels from './channels.reducer';
import channelsSubs from './channelsSubs.reducer';
import groups from './groups.reducer';
import groupsSubs from './groupsSubs.reducer';
import agentsLocations from './agentsLocations.reducer';
import agentDevices from './agentDevices.reducer';
import mapProviders from './mapProviders.reducer';
import admins from './admins.reducer';
import tenantSettings from './tenantSettings.reducer';
import widgets from './widgets.reducer';
import superAdmins from './superAdmins.reducer';
import tenants from './tenants.reducer';
import map from './map.reducer';
import confirmations from './confirmations.reducer';
import license from './licenses.reducer';
import weatherForecastReducer from './weather.reducer';
import rtpMulticastGroups from './rtpMulticastGroups.reducer';
import videoStreams from './videoStreams.reducer';
import devices from './devices.reducer';
import devicesLocations from './devicesLocations.reducer';
import nfcTags from './nfcTags.reducer';

const reducer = combineReducers({
	agents,
	agentsLocations,
	agent2AgentSubs,
	user,
	channels,
	channelsSubs,
	groups,
	groupsSubs,
	agentDevices,
	mapProviders,
	superAdmins,
	admins,
	tenants,
	tenantSettings,
	map,
	confirmations,
	widgets,
	license,
	weatherForecastReducer,
	rtpMulticastGroups,
	videoStreams,
	devices,
	devicesLocations,
	nfcTags
});

export default reducer;
