const locale = {
	APPLICATIONS: 'Εφαρμογές',
	DASHBOARDS: 'Πίνακες ελέγχου',
	MANAGEAGENTS: 'Διαχείριση Χρηστών',
	AGENTSMENU: 'Χρήστες',
	NEWAGENT: 'Νέος Χρήστης',
	AGENTDETAILS: 'Στοιχεία Χρήστη',

	AGENTTOAGENTSUBS: 'Δικαιώματα μεταξύ χρηστών',
	CHANNELSUBS: 'Δικαιώματα Καναλιών',
	GROUPSUBS: 'Δικαιώματα Ομάδας',
	GROUPSSUBS: 'Δικαιώματα Ομάδων',
	MANAGEGROUPS: 'Διαχείριση Ομάδων',
	GROUPSMENU: 'Ομάδες',
	NEWGROUP: 'Νέα Ομάδα',

	MANAGECHANNELS: 'Διαχείριση Καναλιών',
	CHANNELSMENU: 'Κανάλια',
	NEWCHANNEL: 'Νέο Κανάλι',
	CHANNELDETAILS: 'Στοιχεία Καναλιού',
	CALENDAR: 'Ημερολόγιο',
	TENANTSETTINGS: 'Ρυθμίσεις Οργανισμού',
	ECOMMERCE: 'Ηλεκτρονικό Εμπόριο',
	ACADEMY: 'Ακαδημία',
	MAIL: 'Mail',
	TODO: 'To-Do',
	FILE_MANAGER: 'Διαχείριση αρχείων',
	CONTACTS: 'Επαφές',
	CHAT: 'Chat',
	SCRUMBOARD: 'Scrumboard',
	NOTES: 'Σημειώσεις',

	TENANT: 'Φορέας',
	TENANTSET: 'Ρυθμίσεις Φορέα',
	TENANTADMINS: 'Διαχειριστές Φορέα',
	MAPPROV: 'Πάροχοι Χαρτών',
	AGENTDEVICES: 'Συσκευές Χρηστών',
	CHANNELSSTATUS: 'Κατάσταση Εγγραφών',
	GROUPSSTATUS: 'Κατάσταση Εγγραφών',
	RTP_MULTICAST_GROUPS: 'Ομάδες Πολυεκπομπής RTP',
	VIDEO_STREAMS: 'Ροές Βίντεο',
	RECORDING_HISTORY: 'Ιστορικό Εγγραφών Βίντεο',
	INTEGRATED_DEVICES: 'Ενσωματωμένες Συσκευές',
	NFC_TAGS: 'Ετικέτες NFC'
};

export default locale;
