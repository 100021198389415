import * as _ from 'lodash';
import * as Actions from '../actions';

const initialState = {
	nfcTagsDataMap: null,
	nfcTagsData: null,
	searchText: '',
	nfcTagData: null,
	nfcTagDialogData: {
		state: false,
		id: 'new'
	}
};

const nfcTagsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_NFC_TAGS: {
			if (action.payload.length > 0) {
				const sortedData = _.sortBy(action.payload, 'name');
				const nfcTagsDataMap = new Map();

				sortedData.map(item => {
					nfcTagsDataMap.set(item.uid, item);
					return item;
				});
				return {
					...state,
					nfcTagsData: sortedData,
					nfcTagsDataMap
				};
			}

			return {
				...state,
				nfcTagsData: [],
				nfcTagsDataMap: new Map()
			};
		}
		case Actions.SET_NFC_TAGS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_NFC_TAG: {
			const newMap = state.nfcTagsDataMap;
			newMap.set(action.payload.uid, action.payload);
			return {
				...state,
				nfcTagsData: state.nfcTagsData.concat(action.payload),
				nfcTagsDataMap: newMap
			};
		}
		case Actions.GET_NFC_TAG: {
			return {
				...state,
				nfcTagData: action.payload
			};
		}
		case Actions.SAVE_NFC_TAG: {
			const data = state.nfcTagsData.filter(item => item._id !== action.payload._id);
			const data2 = data.concat(action.payload);

			const newMap = state.nfcTagsDataMap;
			newMap.set(action.payload.uid, action.payload);

			return {
				...state,
				nfcTagData: null,
				nfcTagsData: data2,
				nfcTagsDataMap: newMap
			};
		}
		case Actions.NFC_TAGS_DIALOG_DATA: {
			return {
				...state,
				nfcTagDialogData: {
					state: action.payload.state,
					id: action.payload.id
				}
			};
		}
		case Actions.DELETE_NFC_TAG: {
			const newMap = state.nfcTagsDataMap;
			newMap.delete(action.payload.uid);

			return {
				...state,
				delMapProvider: action,
				nfcTagData: null,
				nfcTagsData: state.nfcTagsData.filter(data => data._id !== action.payload._id),
				nfcTagsDataMap: newMap
			};
		}
		case Actions.CLEAR_NFC_TAGS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default nfcTagsReducer;
