import * as _ from 'lodash';
import * as Actions from '../actions';

const initialState = {
	videoStreamsData: null,
	searchText: '',
	videoStreamData: null,
	videoStreamDialogData: {
		state: false,
		id: 'new'
	}
};

const videoStreamsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_VIDEO_STREAMS: {
			if (action.payload.length > 0) {
				const sortedData = _.sortBy(action.payload, 'name');
				return {
					...state,
					videoStreamsData: sortedData
				};
			}

			return {
				...state,
				videoStreamsData: []
			};
		}
		case Actions.SET_VIDEO_STREAMS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_VIDEO_STREAM: {
			return {
				...state,
				videoStreamsData: state.videoStreamsData.concat(action.payload)
			};
		}
		case Actions.GET_VIDEO_STREAM: {
			return {
				...state,
				videoStreamData: action.payload
			};
		}
		case Actions.SAVE_VIDEO_STREAM: {
			const data = state.videoStreamsData.filter(item => item._id !== action.payload._id);
			const data2 = data.concat(action.payload);
			return {
				...state,
				videoStreamData: null,
				videoStreamsData: data2
			};
		}
		case Actions.VIDEO_STREAMS_DIALOG_DATA: {
			return {
				...state,
				videoStreamDialogData: {
					state: action.payload.state,
					id: action.payload.id
				}
			};
		}
		case Actions.DELETE_VIDEO_STREAM: {
			return {
				...state,
				delMapProvider: action,
				videoStreamData: null,
				videoStreamsData: state.videoStreamsData.filter(data => data._id !== action.payload._id)
			};
		}
		case Actions.CLEAR_VIDEO_STREAMS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default videoStreamsReducer;
