import * as Actions from '../actions';

const initialState = {
	devicesLocationsData: null,
	devicesLocationsDataMap: null
};

const devicesLocations = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_DEVICES_LOCATIONS: {
			if (action.payload?.length > 0) {
				const devicesMap = new Map();
				action.payload.forEach(item => {
					devicesMap.set(item.sourceId, item);
				});
				return {
					...state,
					devicesLocationsData: action.payload,
					devicesLocationsDataMap: devicesMap
				};
			}
			return {
				...state,
				devicesLocationsData: []
			};
		}
		case Actions.CLEAR_DEVICES_LOCATIONS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default devicesLocations;
