import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_DEVICE = '[MANAGE-COMMAND APP] GET DEVICE';
export const SET_DEVICES_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET DEVICES SEARCH TEXT';
export const NEW_DEVICE = '[MANAGE-COMMAND APP] NEW DEVICE';

export const GET_DEVICES = '[MANAGE-COMMAND APP] GET DEVICES';
export const SAVE_DEVICE = '[MANAGE-COMMAND APP] SAVE DEVICE';
export const DELETE_DEVICE = '[MANAGE-COMMAND APP] DELETE DEVICE';
export const CLEAR_DEVICES_STORE = '[MANAGE-COMMAND APP] CLEAR DEVICES STORE';
export const DEVICES_DIALOG_DATA = '[MANAGE-COMMAND APP] DEVICES DIALOG DATA';

export function clearDevicesStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_DEVICES_STORE
		});
}

export function deviceDialogData(state) {
	return {
		type: DEVICES_DIALOG_DATA,
		payload: state
	};
}

export function newDevice(data, message, errorMessage) {
	const request = axios.post('EpttaAdmin/Devices', data);

	return dispatch =>
		request.then(response => {
			if (response.status === 226) {
				return dispatch(showMessage({ variant: 'error', message: errorMessage }));
			}

			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: NEW_DEVICE,
				payload: response.data
			});
		});
}

export function getDevices() {
	const request = axios.get(`EpttaAdmin/Devices`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_DEVICES,
				payload: response.data
			})
		);
}

export function setDevicesSearchText(event) {
	return {
		type: SET_DEVICES_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getDevice(streamId) {
	const request = axios.get(`EpttaAdmin/Devices/${streamId}`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_DEVICE,
				payload: response.data
			})
		);
}

export function saveDevice(data, message) {
	const request = axios.put('EpttaAdmin/Devices', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: SAVE_DEVICE,
				payload: data
			});
		});
}

export function deleteDevice(data, message) {
	const request = axios.delete(`EpttaAdmin/Devices?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message }));

			return dispatch({
				type: DELETE_DEVICE,
				payload: data
			});
		});
}

export function newDevicesTemplate(tenant) {
	const data = {
		_id: 'new',
		tenantId: tenant,
		deviceId: '',
		imei: '',
		name: '',
		sourceType: 'TRACKER',
		permittedAgents: [],
		created: '2024-03-30T12:13:24.854Z',
		lastUpdate: '2024-03-30T12:13:24.854Z'
	};

	return {
		type: GET_DEVICE,
		payload: data
	};
}
